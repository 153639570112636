export const discountCalculation1 = (data) => {
    const filteredVisibleData = data.filter(item => item.isVisible)
    const groupedData = {}

    const currentYear = new Date().getFullYear()
            const currentMonth = new Date().getMonth()
            const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate()
            filteredVisibleData.forEach(eachEntry => {
                const hostelName = eachEntry.roomType
                const date = new Date(eachEntry.fromDate)
                if (date.getFullYear() === currentYear && date.getMonth() === currentMonth) {
                    if (!groupedData[hostelName]) {
                        groupedData[hostelName] = {
                            hostelName,
                            vendorDiscount: Array(daysInMonth).fill(0),
                        };
                    }
                    groupedData[hostelName].vendorDiscount[date.getDate() - 1] = eachEntry.vendorDiscount;
                }
            })
    return Object.values(groupedData)
}

export const convertDiscountToData = (discountData) => {
    const originalData = [];
    const hostelName = discountData.hostelName;

    for (let i = 0; i < discountData.vendorDiscount.length; i++) {
        const vendorDiscount = discountData.vendorDiscount[i];
        if (vendorDiscount !== 0) {
            const date = new Date(new Date().getFullYear(), new Date().getMonth(), i + 2).toISOString().split('T')[0];
            originalData.push({
                id: `123-${hostelName}-${date}`,
                created_at: new Date(),
                roomType: hostelName,
                hostelId: "123",
                date: date,
                isVisible: true,
                vendorDiscount,
            });
        }
    }
    return originalData;
}