import html2pdf from 'html2pdf.js';

export const generateHTMLToPDF = (reservations,startDate,endDate) => {

  // removing some unwanted columns from reservations 
  const formattedReservations = reservations.map((reservation) => {
    const formattedReservation = { ...reservation };
    delete formattedReservation.created_at;
    delete formattedReservation.hostel_id;
    delete formattedReservation.payment_id;

    if (formattedReservation.bookingDate) {
      formattedReservation.bookingDate = formattedReservation.bookingDate.split('T')[0];
    }
    return formattedReservation;
  });


  // Dynamically get the keys for the table headers
  const keys = Object.keys(formattedReservations[0]);

  const content = `
    <div>
  <h2 style="text-align: center; margin-bottom: 10px;">
      Reservation Table 
      <span style="font-size: 18px; font-weight: normal; color: black;">${startDate} - ${endDate}</span>
    </h2>
      <table border="1"  style="width: 100%; border-collapse: collapse; text-align: left; font-size: 10px;">
        <thead>
          <tr>
            ${keys
              .map(
                (key) =>
                  `<th style="padding: 2px; border: 1px solid black; background: #f0f0f0; color: black; text-align: center;">${key}</th>`
              )
              .join('')}
          </tr>
        </thead>
        <tbody>
          ${formattedReservations
            .map(
              (reservation) =>
                `<tr>
                  ${keys
                    .map(
                      (key) =>
                        `<td style="padding: 2px; border: 1px solid black; text-align: center;">${reservation[key]}</td>`
                    )
                    .join('')}
                </tr>`
            )
            .join('')}
        </tbody>
      </table>
    </div>
  `;

  // Create a temporary element to hold the content
  const element = document.createElement('div');
  element.innerHTML = content;

  // Generate the PDF
  const options = {
    margin: 10,
    filename: `reservations_${startDate}__${endDate}.pdf`,
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }, // Landscape mode for better fit
  };

  html2pdf().set(options).from(element).save();
};
