import React from "react";
import "../index.css";
import { FaCheckCircle } from "react-icons/fa";

function SuccessCard({ message, onClose }) {
  return (
    <div className="success-modal-backdrop">
      <div className="success-modal">
        <div className="success-icon">
          <FaCheckCircle size={60} color="#4CAF50" />
        </div>
        <h2>Success!</h2>
        <p>{message || "Your file has been successfully uploaded."}</p>
        <button className="close-btn" onClick={onClose}>
          OK
        </button>
      </div>
    </div>
  );
}

export default SuccessCard;