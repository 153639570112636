import React from "react";
import "../index.css";
import { FaTimesCircle } from "react-icons/fa";

function FailureCard({ message, onClose }) {
  return (
    <div className="failure-modal-backdrop">
      <div className="failure-modal">
        <div className="failure-icon">
          <FaTimesCircle size={60} color="#F44336" />
        </div>
        <h2>Failure!</h2>
        <p>{message || "An error occurred while processing your request."}</p>
        <button className="close-btn" onClick={onClose}>
          OK
        </button>
      </div>
    </div>
  );
}

export default FailureCard;