import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { InputGroup, InputGroupText, Input   } from "reactstrap";
import { convertPayments } from "../Utils/payments";
import axios from "axios";
import { baseUrl } from "..";
import Tooltip from "@mui/material/Tooltip";
import { generateHTMLToPDF } from "../Utils/htmlToPdf";
class Payments extends React.Component {
  constructor() {
    super();
    this.state = {
      pageSize: 10,
      currentPage: 0,
      searchInput: "",
      allTableData: [],
      weeklyReservationData: [],
      column: [
        {
          field: "startDate",
          headerName: "Start Date",
          width: "110",
        },
        {
          field: "endDate",
          headerName: "End Date",
          width: "110",
        },
        {
          field: "noOfBeds",
          headerName: "No of Beds",
          width: "110",
        },
        {
          field: "costOfBed",
          headerName: "Cost of Bed",
          width: "110",
        },
        {
          field: "collectionPeriod",
          headerName: "THC Commission",
          width: "130",
        },
        {
          field: "tax",
          headerName: "Tax",
          width: "50",
        },
        {
          field: "thcDiscountCoupons",
          headerName: "THC (Discount + Coupons)",
          width: "200",
        },
        {
          field: "totalValueReceived",
          headerName: "Total Value Received by Hostel",
          width: "220",
        },
        {
          field: "collectionAtPos",
          headerName: "Collection @ POS",
          width: "140",
        },
        {
          field: "collectedAtCheckin",
          headerName: "Collected by Hostel @ Point of Checkin",
          width: "280",
        },
        {
          field: "isTransferred",
          headerName: "Is it Transferred?",
          editable: true,
          width: "140",
        },
        {
          field: "downloadInvoice",
          headerName: "Download Invoice",
          width: "180",
          renderHeader: () => (
            <Tooltip title=" download the full details of the week" arrow>
              <span>Download Invoice</span>
            </Tooltip>
          ),
          renderCell: (params) => (
            <div
              onClick={() => downloadWeekInvoice(params)}
              style={{
                // backgroundColor: '#1976d2',
                color: "black",
                border: "none",
                padding: "5px 10px",
                cursor: "pointer",
                display: "flex",
                gap: "5px",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <i class="bi bi-download"></i> Download
            </div>
          ),
        },
      ],
    };

    const downloadWeekInvoice = (params) => {
      function getISOWeekDetails(date) {
        const tempDate = new Date(date);
        tempDate.setUTCDate(
          tempDate.getUTCDate() + 4 - (tempDate.getUTCDay() || 7)
        ); // Set to Thursday to ensure we're in the correct week
        const yearStart = new Date(Date.UTC(tempDate.getUTCFullYear(), 0, 1));
        const weekNumber = Math.ceil(
          ((tempDate - yearStart) / 86400000 + 1) / 7
        );

        // Calculate week start (Monday) and week end (Sunday)
        const weekStart = new Date(tempDate);
        weekStart.setUTCDate(
          weekStart.getUTCDate() - (weekStart.getUTCDay() || 7) + 1
        ); // Set to Monday
        const weekEnd = new Date(weekStart);
        weekEnd.setUTCDate(weekEnd.getUTCDate() + 6); // Set to Sunday

        return {
          week: weekNumber,
          year: tempDate.getUTCFullYear(),
          weekStart,
          weekEnd,
        };
      }
      const weekStart = params.row.startDate;
      const weekEnd = params.row.endDate;

      const { week, year } = getISOWeekDetails(weekStart);

      const weekNumber = `${year}-W${week}`;
      const weekData = this.state.weeklyReservationData.map((weeks) => {
        if (weeks.week === weekNumber) {
          generateHTMLToPDF(weeks.reservations ,weekStart ,weekEnd)
        }
      });
    };
  }

  componentDidMount() {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .get(`${baseUrl}/reservations`, headers)
      .then((res) => {
        const reservations = res.data;
        reservations.sort(
          (a, b) => new Date(b.bookingDate) - new Date(a.bookingDate)
        );
        function getISOWeekDetails(date) {
          const tempDate = new Date(date);
          tempDate.setUTCDate(
            tempDate.getUTCDate() + 4 - (tempDate.getUTCDay() || 7)
          ); // Set to Thursday to ensure we're in the correct week
          const yearStart = new Date(Date.UTC(tempDate.getUTCFullYear(), 0, 1));
          const weekNumber = Math.ceil(
            ((tempDate - yearStart) / 86400000 + 1) / 7
          );

          // Calculate week start (Monday) and week end (Sunday)
          const weekStart = new Date(tempDate);
          weekStart.setUTCDate(
            weekStart.getUTCDate() - (weekStart.getUTCDay() || 7) + 1
          ); // Set to Monday
          const weekEnd = new Date(weekStart);
          weekEnd.setUTCDate(weekEnd.getUTCDate() + 6); // Set to Sunday

          return {
            week: weekNumber,
            year: tempDate.getUTCFullYear(),
            weekStart,
            weekEnd,
          };
        }

        // Group reservations by ISO week
        const weeklyData = reservations.reduce((acc, reservation) => {
          const bookingDate = new Date(reservation.bookingDate);
          const { week, year, weekStart, weekEnd } =
            getISOWeekDetails(bookingDate);

          const weekKey = `${year}-W${week}`; // Unique key for each week

          if (!acc[weekKey]) {
            acc[weekKey] = {
              week,
              year,
              weekStart,
              weekEnd,
              reservations: [],
            };
          }

          acc[weekKey].reservations.push(reservation);
          return acc;
        }, {});

        // Convert the weekly data into an array of weeks
        const formattedWeeklyData = Object.entries(weeklyData).map(
          ([weekKey, data]) => ({
            week: `${data.year}-W${data.week}`,
            weekStart: data.weekStart,
            weekEnd: data.weekEnd,
            reservations: data.reservations,
          })
        );

        this.setState({ weeklyReservationData: formattedWeeklyData }, () => {
          const aggregateWeeklyData = () => {
            let aggregatedWeeklyData = [];

            this.state.weeklyReservationData.forEach((weeklyData) => {
              const week = weeklyData.reservations;
              const totalBedsSum = week.reduce(
                (sum, r) => sum + r?.totalRooms,
                0
              );
              const costOfBeds = Math.round(
                week.reduce((sum, r) => sum + r?.originalPrice, 0)
              );
              const totalTax = Math.round(
                week.reduce((sum, r) => sum + r?.totalTax, 0)
              );
              const totalHostelRevenue = Math.round(
                week.reduce((sum, r) => sum + r?.totalHostelRevenue, 0)
              );
              const thcComission = week.reduce(
                (sum, r) => sum + r.comissionAmt,
                0
              );
              const thcDiscount = Math.round(
                week.reduce((sum, r) => sum + r?.thcCouponAmt, 0)
              );
              const PaymentCollectedFromCustomer = Math.round(
                week.reduce(
                  (sum, r) => sum + r?.PaymentCollectedFromCustomer,
                  0
                )
              );
              const PaymentPendingfromCustomer = Math.round(
                week.reduce((sum, r) => sum + r?.PaymentPendingFromCustomer, 0)
              );

              const StarDatetTimestamp = new Date(weeklyData.weekStart);
              const startDate = StarDatetTimestamp.toISOString().split("T")[0];
              const endDateTimestamp = new Date(weeklyData.weekEnd);
              const endDate = endDateTimestamp.toISOString().split("T")[0];
              const aggregatedWeek = {
                startDate: startDate,
                endDate: endDate,
                totalBeds: totalBedsSum,
                costOfBeds,
                totalTax,
                totalHostelRevenue,
                thcComission,
                thcDiscount,
                PaymentCollectedFromCustomer,
                PaymentPendingfromCustomer,
              };
              aggregatedWeeklyData.push(aggregatedWeek);
            });

            // console.log(aggregatedWeeklyData);
            const formattedWeeklyData = convertPayments(aggregatedWeeklyData);
            this.setState({ allTableData: formattedWeeklyData });
          };

          aggregateWeeklyData();
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  // const column = [
  //   {
  //     field: "startDate",
  //     headerName: "Start Date",
  //     width: "110",
  //   },
  //   {
  //     field: "endDate",
  //     headerName: "End Date",
  //     width: "110",
  //   },
  //   {
  //     field: "noOfBeds",
  //     headerName: "No of Beds",
  //     width: "110",
  //   },
  //   {
  //     field: "costOfBed",
  //     headerName: "Cost of Bed",
  //     width: "110",
  //   },
  //   {
  //     field: "collectionPeriod",
  //     headerName: "THC Commission",
  //     width: "130",
  //   },
  //   {
  //     field: "tax",
  //     headerName: "Tax",
  //     width: "50",
  //   },
  //   {
  //     field: "thcDiscountCoupons",
  //     headerName: "THC (Discount + Coupons)",
  //     width: "200",
  //   },
  //   {
  //     field: "totalValueReceived",
  //     headerName: "Total Value Received by Hostel",
  //     width: "220",
  //   },
  //   {
  //     field: "collectionAtPos",
  //     headerName: "Collection @ POS",
  //     width: "140",
  //   },
  //   {
  //     field: "collectedAtCheckin",
  //     headerName: "Collected by Hostel @ Point of Checkin",
  //     width: "280",
  //   },
  //   {
  //     field: "isTransferred",
  //     headerName: "Is it Transferred?",
  //     editable: true,
  //     width: "140",
  //   },
  //   {
  //     field: "downloadInvoice",
  //     headerName: "Download Invoice",
  //     width: "180",
  //     renderHeader: () => (
  //       <Tooltip title=" download the full details of the week" arrow>
  //         <span>Download Invoice</span>
  //       </Tooltip>
  //     ),
  //     renderCell: (params) => (
  //       <div
  //         onClick={() =>downloadWeekInvoice()}
  //         style={{
  //           // backgroundColor: '#1976d2',
  //           color: 'black',
  //           border: 'none',
  //           padding: '5px 10px',
  //           cursor: 'pointer',
  //           display:"flex",
  //           gap:"5px",
  //           alignContent:"center",
  //           alignItems:"center"
  //         }}
  //       >
  //      <i class="bi bi-download"></i>   Download
  //       </div>
  //     ),
  //   },
  // ];
  render() {
    const onChange = (e) => {
      const { name, value } = e.target;
      if (name === "pageSize") {
        this.setState({ currentPage: 0 });
      }
      this.setState({ [name]: value });
    };
    return (
      <div className="m-5">
        <div className="d-flex justify-content-between mb-3">
          <InputGroup className="border rounded w-25">
            <InputGroupText className="bg-transparent border-0">
              <i class="bi bi-search"></i>
            </InputGroupText>
            <Input
              className="border-0"
              placeholder="Search..."
              onChange={onChange}
              value={this.state.searchInput}
              name="searchInput"
            />
          </InputGroup>
          {/* <Button className="bg-secondary-o text-dark-o border-0" color="dark">
                        Download
                    </Button> */}
        </div>
        <DataGrid
          rows={this.state.allTableData}
          columns={this.state.column}
          pageSize={this.state.pageSize}
          rowsPerPageOptions={[1, 2, 4]}
          pagination
          autoHeight
          onPageChange={(params) => this.setState({ currentPage: params.page })}
          onPageSizeChange={(params) =>
            this.setState({ pageSize: params.pageSize })
          }
          getRowId={(row) => row.id}
        />
      </div>
    );
  }
}

export default Payments;
