import React from "react"
import Moment from "react-moment"
import axios from "axios"
import { baseUrl } from ".."
import { Input, Label, Button, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap"
import {discountCalculation1, convertDiscountToData} from "../Utils/discountCalculation"
import * as XLSX from "xlsx"
import SuccessCard from "../Components/SuccessCard.js" 
import FailureCard from "../Components/FailureCard.js" 

class Discount extends React.Component {
    constructor() {
        super()
        this.state = {
            searchInput: "",
            daysInMonth: 0,
            isAddModal: false,
            noOfEntries: 1,
            currentPage: 1,
            totalPages: 0,
            fromDate: "",
            toDate: "",
            today: "",
            last: "",
            isBulkUpload: false,
            excel: null,
            hostel: {},
            isLoading: false,
            discountData: [],
            discountCalculation: [],
            showSuccess: false,
            showFailure: false,
        }
    }

    componentDidMount() {
        const { noOfEntries } = this.state
        const headers = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }
        axios.get(`${baseUrl}/inventory/all`, headers).then(response => {
            const discountData = discountCalculation1(response.data.data);
            console.log(discountData);
            const daysInMonth = discountData[0]?.vendorDiscount.length
            const totalPages = discountData.length / noOfEntries
            const today = new Date()
            const yyyy = today.getFullYear()
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const dd = today.getDate()
            const lastDay = new Date(yyyy, mm, 0);
            const lastDayOfMonth = String(lastDay.getDate()).padStart(2, '0');
            this.setState({ fromDate: `${yyyy}-${mm}-${String(dd).padStart(2, "0")}`, today: `${yyyy}-${mm}-${String(dd).padStart(2, "0")}` })
            this.setState({ toDate: `${yyyy}-${mm}-${lastDayOfMonth}`, last: `${yyyy}-${mm}-${lastDayOfMonth}` })
            this.setState({ discountData, daysInMonth, totalPages, discountCalculation: response.data, hostel: response.data.hostelData[0] })
        }).catch(err => {
            console.log(err.message)
        })
    }

    render() {

        const onChangeDate = (e) => {
            const { name, value } = e.target
            const { fromDate, toDate, discountCalculation } = this.state
            this.setState({ [name]: value }, () => {
                const discountDataCalc = discountCalculation1(discountCalculation, parseInt(fromDate.split("-")[2]), parseInt(toDate.split("-")[2]))
                this.setState({ discountData: discountDataCalc })
            })
        }
        
        const onChangeValue = (e) => {
            let { name, value } = e.target
            const { discountData } = this.state
            const user = name.split("-")
            const [field, date, index] = user
            if (value === "")
                value = 0
            discountData[index][field][date] = parseInt(value)
            this.setState({ discountData })
        }

        const onSave = (index) => {
        const { discountData } = this.state
        const convertedData = convertDiscountToData(discountData[index])
        console.log (convertedData)
        const headers = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }
        convertedData.map(eachData => {
            return axios.put(`${baseUrl}/discount/${eachData.id}`, eachData, headers).then(response => {
                console.log(response.status)
            }).catch(err => {
                console.log(err.message)
            })
        })
        }
        const handleFileUpload = (e) => {
            const file = e.target.files[0];
            this.setState({ excel: file })
        }

        const convertExcelDateToJSDate = (excelDate) => {
            const excelEpoch = new Date(1900, 0, 1);
            const daysOffset = excelDate - 2;
            return new Date(excelEpoch.getTime() + daysOffset * 24 * 60 * 60 * 1000);
        };
        const closeModal = () => {
            this.setState({ showSuccess: false, showFailure: false });
            window.location.reload()
        };
        const onSubmit = async () => {
            this.setState({ isLoading: true })
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const processedJson = json.map((row) => 
                    row.map((cell, index) => 
                        row[1] === "Metric" && index >= 2
                            ? convertExcelDateToJSDate(cell).toLocaleDateString()
                            : cell
                    )
                );

                console.log (processedJson)

                const token = localStorage.getItem("token")
                const headers = {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
                axios.post(`${baseUrl}/inventory/excel-discount`, processedJson, headers).then(response => {
                    this.setState ({isLoading: false, showSuccess: true})
                }).catch(err => {
                    this.setState ({isLoading: false, showFailure: true})
                    console.log(err.message)
                })
            };

            reader.readAsArrayBuffer(this.state.excel);
        }

        const isDisabled = !(this.state.excel !== null)

        return(
            <div className="mx-5 mt-3">
                <h2 className= "fw-bold mb-3">Discount</h2>
                <div className="d-flex flex-wrap justify-content-between align-items-center gap-3 mb-5">
                    <div className='d-flex gap-5 align-items-center'>
                        <div>
                            <Label>From</Label>
                            <Input name="fromDate" type="date" placeholder="Select date" />
                        </div>
                        <div>
                            <Label>To</Label>
                            <Input  name="toDate" type="date" placeholder="Select date" />
                        </div>
                    </div>
                    <div className='d-flex gap-3 align-items-center'>
                        <Button onClick={() => this.setState({ isBulkUpload: true })} className="btn bg-secondary-o text-dark-o border-0" color="dark"> <i className='bi bi-plus-lg'></i> Bulk Upload</Button>
                    </div>
                    
                                        
                </div>
                <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    <table className="discount-table mb-5" style={{ width: "max-content" }}>
                    <thead>
                            <tr>
                                <th className="h2 fw-bold">
                                    <Moment format="MMM">
                                        {new Date()}
                                    </Moment>
                                </th>
                                {Array.from({ length: this.state.daysInMonth }, (_, index) => {
                                    const date = new Date("2024", new Date().getMonth(), index + 1)
                                    if (index + 1 >= this.state.fromDate.split("-")[2] && index + 1 <= this.state.toDate.split("-")[2])
                                        return (
                                            <td style={{ width: "5rem" }} className="text-nowrap text-center" key={index}>
                                                <div className={new Date().getDate() === date.getDate() ? "text-dark-o fs-4 fw-bold" : "text-secondary-o"}>
                                                    <Moment format="ddd">
                                                        {date.toDateString()}
                                                    </Moment>
                                                </div>
                                                <div className={`text-dark-o ${new Date().getDate() === date.getDate() ? "fs-4 fw-bold" : ""}`}>
                                                    <Moment format="D">
                                                        {date.toDateString()}
                                                    </Moment>
                                                </div>
                                            </td>
                                        )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {
                            this.state.discountData.map((eachInventory, index) => {
                                    return ["Name", "Vendor Discount"].map(eachRow => {
                                        if (eachRow === "Name")
                                            return (
                                                <tr key={eachRow.hostelName} aria-colspan={this.state.daysInMonth}>
                                                    <th className="d-flex gap-3 align-items-center my-3">
                                                        <div className="text-tertiary-o fs-5 fw-bold text-nowrap">{eachInventory?.hostelName}</div>
                                                        <div>
                                                            <Button onClick={() => onSave(index)} className="bg-dark-o text-text" size="sm">
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </th>
                                                </tr>
                                            )
                                        if (eachRow === "Vendor Discount")
                                            return (
                                                <tr className="border">
                                                    <th>
                                                        {eachRow}
                                                    </th>
                                                    {eachInventory.vendorDiscount.map((eachItem, dateIndex) => {
                                                        if (dateIndex + 1 >= this.state.fromDate.split("-")[2] && dateIndex + 1 <= this.state.toDate.split("-")[2])
                                                            return (
                                                                <td style={{ width: "5rem" }} className="text-nowrap text-center" key={`${eachItem}-${dateIndex}`}>
                                                                    <Input disabled={dateIndex + 1 < new Date().getDate()} onChange={onChangeValue} min="0"
                                                                        value={this.state.discountData[index].vendorDiscount[dateIndex]} name={`vendorDiscount-${dateIndex}-${index}`} placeholder="0" className="text-center" />
                                                                </td>
                                                            )
                                                    })}
                                                </tr>)
                                    })

                                })}                            
                        </tbody>
                    </table>
                </div>
                {(!this.state.showSuccess && !this.state.showFailure) && <Modal isOpen={this.state.isBulkUpload} toggle={() => this.setState({ isBulkUpload: false })}>
                    <ModalHeader toggle={() => this.setState({ isBulkUpload: false })}>Bulk Upload Discount</ModalHeader>
                    <ModalBody>
                        <div className="d-flex justify-content-end">
                            <a href="/discount_sample.xlsx" download="discount_sample.xlsx" className="text-dark-o d-flex gap-3 text-decoration-underline" style={{ cursor: "pointer" }} >
                                <i className="bi bi-download"></i>Download Sample
                            </a>
                        </div>
                        <input
                            type="file"
                            accept=".xlsx"
                            onChange={handleFileUpload}
                            style={{
                                padding: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                cursor: 'pointer'
                                    }}
                                id="upload-btn"
                                hidden
                        />
                        {this.state.excel === null ?
                        <label className="upload-box" color="dark" htmlFor="upload-btn">Upload File here</label> :
                        <label className="upload-box" color="dark" htmlFor="upload-btn">File uploaded {this.state.excel.name}</label>}
                        <div className="d-flex justify-content-end">
                            <Button disabled={isDisabled || this.state.isLoading} onClick={onSubmit} color="success">
                                {this.state.isLoading ? <Spinner /> :
                                    "Submit"}
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>}
                {this.state.showSuccess && <SuccessCard
                    onClose={closeModal}
                />}
                {this.state.showFailure && <FailureCard
                    onClose={closeModal}
                />}      
            </div>
        )
    }

    
}
export default Discount